import { underscore } from '@ember/string';
import JSONAPISerializer from '@ember-data/serializer/json-api';

export default class MessagingBase extends JSONAPISerializer {
  keyForAttribute(attr) {
    return underscore(attr);
  }

  keyForRelationship(relationshipName) {
    return underscore(relationshipName);
  }

  modelNameFromPayloadKey(payloadType) {
    return super.modelNameFromPayloadKey(`messaging-${payloadType}`);
  }

  payloadKeyFromModelName(modelName) {
    return super.payloadKeyFromModelName(modelName.replace('messaging-', ''));
  }
}
