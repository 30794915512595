/* import __COLOCATED_TEMPLATE__ from './payment-amount-list.hbs'; */
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

export default class SiteBillingPaymentModalPaymentAmountList extends Component {
  @service billingModals;
  @service stripeExpressCheckoutElementService;

  @tracked selectedPaymentAmountType = null;

  get showOtherAmountInput() {
    return this.selectedPaymentAmountType === 'other';
  }

  get totalBalanceIsNotPositive() {
    return this.billingModals.balanceDue <= 0;
  }

  get totalBalanceCreditSuffix() {
    return this.billingModals.balanceDue < 0 ? 'Credit' : '';
  }

  get totalBalanceIsLessThanInvoice() {
    return this.billingModals.balanceDue < this.args.model._invoice.remainingAmount;
  }

  get isExpressCheckoutElementBusy() {
    return (
      this.stripeExpressCheckoutElementService.isOpen ||
      this.stripeExpressCheckoutElementService.isPersisting
    );
  }

  get displayTotalBalanceHelp() {
    return this.args.model._invoice && this.totalBalanceIsLessThanInvoice;
  }
}
